import { Flex } from '@chakra-ui/react'
import React, { useContext } from 'react'
import MagikLogo from './MagikLogo'
import Collapsible from './Collapsible'
import { StateStore } from '../Store'
import { useRouter } from 'next/router'
import { get } from 'lodash'

type Props = {}

const Header = (props: Props) => {
  const { state, dispatch } = useContext(StateStore)
  const { push } = useRouter()

  const collapsed = get(state, 'sidebar.collapsed', false)

  return (
    <Flex
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      grow={1}
      padding={2}
      zIndex={20}
      marginTop={6}
      height={'40px'}
      backgroundColor={'rgb(248, 248, 248)'}
    >
      <MagikLogo
        {...{
          collapsed,
        }}
      />
      <Collapsible
        {...{
          onClick: () => {
            dispatch({
              type: 'TOGGLE_SIDEBAR',
              payload: !collapsed,
            })
          },
          collapsed,
        }}
      />
    </Flex>
  )
}

export default Header
