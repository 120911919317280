import { tw } from '@minupalaniappan/brise'
import React from 'react'

type CollapsibleProps = {
  className?: string
  onClick?: () => void
  collapsed?: boolean
}

const Collapsible = tw((props: CollapsibleProps) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...{
        className: props.className,
        onClick: props.onClick,
      }}
    >
      <path
        d='M14.8333 1.5H3.16667C2.24619 1.5 1.5 2.24619 1.5 3.16667V14.8333C1.5 15.7538 2.24619 16.5 3.16667 16.5H14.8333C15.7538 16.5 16.5 15.7538 16.5 14.8333V3.16667C16.5 2.24619 15.7538 1.5 14.8333 1.5Z'
        stroke='#989898'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.5 1.5V16.5'
        stroke='#989898'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
})`
  cursor-pointer
  relative
  bottom-[1px]

  ${({ collapsed }) => (collapsed ? 'right-[2px]' : '')}
`

export default Collapsible
