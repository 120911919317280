import React from 'react'
import { Layout } from 'antd'
import { ContainerProps } from '../types'
import { tw } from '@minupalaniappan/brise'
import Navbar from './Navbar'
import styled from 'styled-components'
import { Container } from '@chakra-ui/react'

const { Content } = Layout

const StyledLayout = tw(Layout)`
  min-h-screen
  z-[9]
`

const StyledContent = styled(Content)`
  background-color: rgb(248, 248, 248);
  z-index: 9;
`

export type BaseRootModalRefCoreProps = {
  className?: string
}

const AppLayout = (props: ContainerProps) => {
  return (
    <StyledLayout>
      {props.hideSidebar ? null : <Navbar />}
      <Layout className='site-layout'>
        <StyledContent>
          <Container variant={'applicationContainer'}>
            {props.children}
          </Container>
        </StyledContent>
      </Layout>
    </StyledLayout>
  )
}

export default AppLayout
